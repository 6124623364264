import clsx from 'clsx'

import { Image } from 'antd'

function Footer() {
  return (
    <Image
      alt="footer"
      preview={false}
      className={clsx(
        'fixed bottom-0 left-1/2 -translate-x-1/2 translate-y-[calc(50%_+_10px)]',
        'max-w-[1185px] !w-[calc(100vw_-_48px)] -z-[1]',
      )}
      src="/images/footer.svg"
    />
  )
}

export default Footer
