// next
import Link from 'next/link'
// react
import { CSSProperties } from 'react'
import clsx from 'clsx'
// Antd
import { Image } from 'antd'

import configs from '@/configs'

// image
import BrandLogo from '@/assets/images/logo/brand.svg'

type BrandProps = {
  style?: CSSProperties
  disableNav?: boolean
  className?: string
}

function Brand({ style, disableNav = false, className }: BrandProps) {
  return (
    <Link
      href={{ pathname: configs.location.sp3Origin }}
      aria-disabled={disableNav}
      tabIndex={disableNav ? -1 : undefined}
      className={clsx(disableNav && 'pointer-events-none', className)}
    >
      <Image preview={false} src={BrandLogo.src} alt="brand" style={style} />
    </Link>
  )
}

export default Brand
