import { type PropsWithChildren } from 'react'

import { Layout } from 'antd'

import AppContainer from '@/components/containers/AppContainer'
import HeaderDesktop from '@/components/header/HeaderDesktop'
import Footer from '@/components/footer'

import './main-layout-desktop.scss'

function MainLayoutDesktop({ children }: PropsWithChildren) {
  return (
    <Layout>
      <HeaderDesktop />
      <Layout className="main-layout-desktop">
        <Layout.Content>
          <AppContainer>{children}</AppContainer>
        </Layout.Content>
      </Layout>
      <Footer />
    </Layout>
  )
}

export default MainLayoutDesktop
