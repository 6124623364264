import { Col, Row, Space } from 'antd'

import MenuNav from './MenuNav'
import Brand from '@/components/systems/brand'
import CsrProvider from '@/providers/csr.provider'
import ConnectWalletBtn from '@/components/connect-wallet-btn'

import './index.scss'

function HeaderDesktop() {
  return (
    <div className="header-desktop--container">
      <Row
        gutter={24}
        align="middle"
        justify="space-between"
        className="header-desktop--inner"
        wrap={false}
      >
        <Col>
          <Space size={12}>
            <Brand style={{ width: 162 }} />
          </Space>
        </Col>
        <Col className="w-full">
          <CsrProvider>
            <MenuNav />
          </CsrProvider>
        </Col>
        <Col>
          <ConnectWalletBtn />
        </Col>
      </Row>
    </div>
  )
}

export default HeaderDesktop
