// next
import Link from 'next/link'
// react
import { useEffect, useMemo, useState } from 'react'
// antd
import { Menu, MenuProps } from 'antd'
// icons
import Icon from '@ant-design/icons'
import { MissionIcon, LaunchpadIcon, BurnIcon } from '@/assets/images/icon'

import useIsMobile from '@/hooks/system/useIsMobile'

import configs from '@/configs'

import { ItemType } from 'antd/es/menu/interface'

// styles
import './index.scss'

function MenuNav() {
  const isMobile = useIsMobile()
  const [selectedKeys, setSelectedKeys] = useState<MenuProps['selectedKeys']>()

  useEffect(() => {
    setSelectedKeys([window.location.origin])
  }, [])

  const menuItem: ItemType[] = useMemo(() => {
    const menuItems: ItemType[] = [
      {
        label: (
          <Link href={{ pathname: configs.location.mission }}>Missions</Link>
        ),
        key: configs.location.mission,
        icon: <Icon component={MissionIcon} />,
      },
      {
        label: (
          <Link href={{ pathname: configs.location.launchpad }}>Launchpad</Link>
        ),
        key: configs.location.launchpad,
        icon: <Icon component={LaunchpadIcon} />,
      },
      {
        label: (
          <Link href={{ pathname: configs.location.origin }}>Burn NFT</Link>
        ),
        key: configs.location.origin,
        icon: <Icon component={BurnIcon} />,
      },
    ]

    return menuItems
  }, [])

  if (isMobile)
    return (
      <Menu
        mode="vertical"
        items={menuItem}
        className="header--menu-nav--mobile"
        selectedKeys={selectedKeys}
      />
    )

  return (
    <Menu
      mode="horizontal"
      items={menuItem}
      className="header--menu-nav--desktop"
      selectedKeys={selectedKeys}
      rootClassName="nav-desktop"
    />
  )
}

export default MenuNav
